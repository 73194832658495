<div>
  <div class="modal-header">
    <h4 class="modal-title" translate>
      page.declarations.synthese.modal.saisir-transaction.title
    </h4>
    <div class="pointer">
      <fa-icon icon="times" (click)="onClose()"></fa-icon>
    </div>  
  </div>

  <div class="modal-body" >

    <div class="row" *ngIf="transaction">
      <div class="col">
        <div>
          <div class="libelle">{{ 'page.declarations.synthese.modal.saisir-transaction.vendeur' | translate }}</div>
          <div class="valeur" >{{transaction.vendeur}}</div> 
        </div>

       
        <hr/>
        <div class="py-2">
          <div class="libelle">{{ 'page.declarations.synthese.modal.saisir-transaction.produit' | translate }}</div>
          <div class="valeur" >{{transaction.produit.libelle}}</div> 
        </div>

       
        <hr/>

        <div >
          <div class="libelle">{{ 'page.declarations.synthese.modal.saisir-transaction.date-retiraison' | translate }}</div>
          <div class="valeur" >{{transaction.date | date:'dd/MM/yyyy'}}</div> 
        </div>

        <div  class="py-2">
          <div class="libelle" translate>page.declarations.synthese.modal.saisir-transaction.logement</div>
          <div class="valeur" >{{transaction.logement}}</div>
        </div>

        <div  class="py-2">
          <div class="libelle" translate>page.declarations.synthese.modal.saisir-transaction.numeroLot</div>
          <div class="valeur" >{{transaction.numeroLot}}</div>
        </div>

        <div  class="py-2">
          <div class="libelle" translate>page.declarations.synthese.modal.saisir-transaction.numeroContrat</div>
          <span class="valeur">{{transaction.numeroContrat}}</span>
        </div>
      
        <hr /> 
      
        <div  class="py-2">
          <div class="libelle" translate>page.declarations.synthese.modal.saisir-transaction.acheteur</div>
          <div class="flex flex-row acheteur-form-row">
            {{transaction.acheteur}}
          </div>
        </div>
      </div>

      <div class="col">
        

        <!-- Formulaire assemblages -->
           <div  class="py-2" *ngIf="transaction.assemblages">
            <div class="libelle">{{ 'page.declarations.synthese.modal.saisirConditionnement.volume' | translate }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</div>
          
            <div class="row" >
              <div class="col-md-2" translate>page.declarations.synthese.modal.saisir-transaction.assemblage.annee</div>
              <div class="col-md-4" translate>page.declarations.synthese.modal.saisir-transaction.assemblage.volume</div>
            </div>
            <div class="row" *ngFor="let assemblage of transaction.assemblages">
              <div class="col-md-2">{{assemblage.annee}}</div>
              <div class="col-md-4">{{assemblage.volume}}</div>
            </div>            
          </div>
          

        <hr/>

        <div  class="py-2">
          <div class="libelle">{{ 'page.declarations.synthese.modal.saisirConditionnement.volumeTotal' | translate }} ({{ 'page.declarations.synthese.modal.saisirConditionnement.hL' | translate }})</div>
          
          <div class="valeur" >{{transaction.volume}}</div>
        </div>

        <hr/>

        <div  class="py-2">
          <div class="libelle" translate>page.declarations.synthese.modal.saisir-transaction.entreposage</div>
          <div class="valeur" >{{ transaction.site.adresse }}</div>
        </div>

        <div  class="py-2">
          <div class="libelle" translate>page.declarations.synthese.modal.saisir-revendication.observation</div>
          <div class="valeur" >{{ transaction.observation }}</div>
        </div>
      </div>
    </div>

  </div>
</div>