import {Component, Input, OnInit} from '@angular/core';
import {EntrepriseEngagement} from '../../../../../../data/habilitation/models/engagements/entreprise-engagement';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
  EngagementVergersEcoService
} from '../../../../../../data/habilitation/services/engagements/engagement-vergers-eco.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {InfosGenerationAudit} from '../../../../../../data/declaration/models/infos-generation-audit.model';
import {InfosLastValid} from '../../../../../../data/declaration/models/infos-last-validation.model';
import {UniteCulturaleService} from '../../../../../../data/declaration/services/parcellaire/unite-culturale.service';
import {EngagementBase} from '../../../../../../data/habilitation/models/engagements/engagement-base';
import {IntervenantEngagement} from '../../../../../../data/habilitation/models/engagements/intervenant-engagement';
import { GedFacade } from 'src/app/data/edition/facades/ged.facade';
import { RefTypeFichier } from 'src/app/data/edition/models/ref-type-fichier.model';
import { GedFichier } from 'src/app/data/edition/models/ged-fichier.model';
import { zip } from 'rxjs';
import * as moment from 'moment';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UtilisateursService } from 'src/app/data/intervenant/services/utilisateurs/utilisateurs.service';
import { NotificationDocument } from 'src/app/data/intervenant/models/notification-document.model';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import {EntreprisesService} from "../../../../../../data/intervenant/services/entreprises/entreprises.service";

@Component({
  selector: 'app-doc-preparatoire-ct',
  templateUrl: './doc-preparatoire-ct.component.html',
  styleUrls: ['./doc-preparatoire-ct.component.scss']
})
export class DocPreparatoireCtComponent implements OnInit {

  @Input() public campagne;
  @Input() public campagneInventaire;
  @Input() public engagements: EngagementBase[];
  public formGroup: FormGroup;
  public conseillerTechniqueList: IntervenantEngagement[] = [];
  private idConseillerTechniqueList: number[] = [];
  private infosGenerationAudit: InfosGenerationAudit;
  private infosLastValidDateInventaireList: InfosLastValid[];

  get selectedConseillerTechnique() {
    return this.formGroup.get('conseillerTechnique').value as IntervenantEngagement;
  }

  constructor(
    private engagementVergersEcoService: EngagementVergersEcoService,
    public fb: FormBuilder,
    public gedFacade: GedFacade,
    public modal: NgbActiveModal,
    private uniteCulturaleService: UniteCulturaleService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private utilisateursService: UtilisateursService,
    private loaderService: AdvBootstrapLoaderService,
    private entreprisesService: EntreprisesService
  ) { }

  public ngOnInit(): void {
    this.gedFacade.setIdIntervenant(SessionContext.get('idIntervenant'));
    this.gedFacade.loadRefTypesFichier();
    if (this.engagements) {
      this.engagements.forEach(e => {
        if (e.codeStatut === 'DP') {
          if (!this.conseillerTechniqueList.find(ct => ct.id === e.conseillerTechnique.id)) {
            this.conseillerTechniqueList.push(e.conseillerTechnique);
          }
        }
      });
      this.conseillerTechniqueList = this.conseillerTechniqueList.sort((a, b) => (a.nom < b.nom) ? -1 : 1);
    }
    this.formGroup = this.fb.group({
      conseillerTechnique: [undefined]
    });
    this.formGroup.get('conseillerTechnique').setValue(this.conseillerTechniqueList[0]);
  }

  public cancel(): void {
    this.modal.dismiss();
  }

  public buildDocumentAudit(): void {
    this.idConseillerTechniqueList.push(this.selectedConseillerTechnique.id);
    this.engagementVergersEcoService.getIdsEntreprisesLieesCt(this.selectedConseillerTechnique.id, this.campagne.code, true).pipe(this.loaderService.operator()).subscribe(ids => {
      if (ids && ids.length > 0) {
        this.entreprisesService.getInformationsComplementairesPdfVer(ids)
          .pipe(this.loaderService.operator())
          .subscribe((result) => {
            this.uniteCulturaleService.getListMapEntrepriseDateValidationInventaire(ids, this.campagneInventaire.id).pipe(this.loaderService.operator()).subscribe(map => {
              this.infosGenerationAudit = Object.assign(new InfosGenerationAudit(), {
                idEntrepriseList: this.idConseillerTechniqueList,
                mapDateValidationInventaire: map,
                codeOCCT: 'CT',
                complementPoPdfVer: result
              });
              this.engagementVergersEcoService
                .generationDocumentAudit(
                  this.campagne.code,
                  this.infosGenerationAudit
                ).pipe(this.loaderService.operator())
                .subscribe((response: Blob) => {
                  const filename =`audit-ct_${this.selectedConseillerTechnique.nom}_${this.selectedConseillerTechnique.prenom}_${moment().format('YYYY-MM-DDTHHmmss')}.csv`;
                  zip(
                    this.gedFacade.postUploadFile(new File([response], filename)),
                    this.gedFacade.refTypesFichier$
                  ).subscribe(([uuid, typesFichiers]: [string, Array<RefTypeFichier>]) => {
                    this.gedFacade.postFile(Object.assign(new GedFichier(), {
                      uuid: uuid,
                      libelle: filename,
                      typeFichier: typesFichiers.find((typeFichier: RefTypeFichier) => typeFichier.code === 'DOCUMENT'),
                      estPublic: false,
                      estArchive: false,
                      intervenants: this.idConseillerTechniqueList
                    })).subscribe();
                    let notif : NotificationDocument = <NotificationDocument> {
                      idsIntervenant : this.idConseillerTechniqueList,
                      nomDocument : filename
                    };
                    this.utilisateursService.sendNotificationDocumentaire(notif).subscribe();
                    this.toastrService.success(this.translate.instant('page.documents.documents-enregistre'));
                  });
                  //this.downloadFile(response);
                  this.modal.close();
                });
            });
          });
      }
    });
  }

  private downloadFile(data: Blob): void {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    window.open(window.URL.createObjectURL(blob));
  }
}
