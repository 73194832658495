import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/data/edition/services/document/document.service';
import { Document } from 'src/app/data/edition/models/document.model';
import { forkJoin, Observable } from 'rxjs';
import { ReferencesEditionService } from 'src/app/data/edition/services/references-edition/references-edition.service';
import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AbstractRechercheItemComponent } from 'src/app/main/pages/commissions/pages/edit-commission/modals/abstract-recherche-items.component';
import { Controle } from 'src/app/data/declaration/models/controle.model';
import { Echantillon } from 'src/app/data/commission/models/echantillon.model';
import { Commission } from 'src/app/data/commission/models/commission.model';
import { SelectJuresComponent } from '../../../commissions/pages/edit-commission/modals/select-jures/select-jures.component';
import { EditionService } from 'src/app/data/edition/services/edition.service';
import { SessionContext } from 'src/app/core/services/config/app.settings';
import { OperateursService } from 'src/app/data/intervenant/services/operateurs/operateurs.service';
import { CategorieEnum } from 'src/app/data/edition/models/enums/categorie.enum.model';
import { ReferencesEdition } from 'src/app/data/edition/models/references-edition.model';
import { Fichier } from 'src/app/data/edition/models/fichier.model';
import { combineLatest, map, tap } from 'rxjs/operators';
import { FileDownloadTools } from 'src/app/shared/file-download-tools/file-download-tools';
import { OrganismesService } from 'src/app/data/intervenant/services/organismes/organismes.service';
import { OrganismeEdition } from 'src/app/data/edition/models/organisme-edition.model';
import { HistoriqueMouvement } from 'src/app/data/declaration/models/historique-mouvement.model';
import { Tournee } from 'src/app/data/tournee/models/tournee.model';
import { ManquementResultat } from 'src/app/data/declaration/models/ManquementResultat.model';


@Component({
  selector: 'app-telecharger-document',
  templateUrl: './telecharger-document.component.html',
  styleUrls: ['./telecharger-document.component.scss']
})
export class TelechargerDocumentComponent extends AbstractRechercheItemComponent<Document> implements OnInit {

  constructor(
    private readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly documentService: DocumentService,
    private readonly referencesEditionService: ReferencesEditionService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly modalService: NgbModal,
    private readonly editionService: EditionService,
    private readonly operateursService: OperateursService,
    private readonly organismesService: OrganismesService
  ) {
    super(modal);
  }
  @ViewChild('form') form: NgForm;
  formGroup: FormGroup;
  documents: Document[];
  referencesEdition: ReferencesEdition;
  @Input() categorie: CategorieEnum;
  @Input() controle: Controle[] = [];
  @Input() echantillon: Echantillon;  
  @Input() declaration: HistoriqueMouvement;
  @Input() commission: Commission;
  @Input() tournee: Tournee;
  @Input() manquement: ManquementResultat;
  @Input() commissionSendMail: boolean;
  noDocuments: boolean;

  getData(): Observable<Document[]> {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      document: [undefined, Validators.required],
    });

    setTimeout(() => this.loadData());
  }

  loadData() {
    this.referencesEditionService.getReferences()
      .pipe(
        this.loaderService.operator()
      ).subscribe(ref => {
        this.referencesEdition = ref;
        const cat = this.referencesEdition.refCategorieList.find(c => c.code === this.categorie);
        this.documentService.getDocumentsByCategorie(cat.id, SessionContext.get('idOrganisme')).subscribe(documents => {
          this.documents = documents;
        });
      });

  }

  isDocumentsCheck(): void {
    const documents: Document[] = this.getDocumentsChecked(this.documents);
    if(documents && documents.length >0) {
      this.noDocuments = false;
    }
  }

  /*
   si on est dans le cas où il s'agit d'un envoi par mail, on envoie
   la commission, la catégorie et les documents sélectionnés à la modale
   suivante pour sélectionner les jurés
   */
  public next() {
    const documents: Document[] = this.getDocumentsChecked(this.documents);

    if (!documents || documents.length == 0) {
      this.noDocuments = true;
      return;
    }
    this.onClose();
    const modalRef = this.modalService.open(SelectJuresComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.commission = this.commission;
    modalRef.componentInstance.categorie = CategorieEnum.COMMISSION;
    modalRef.componentInstance.documents = this.getDocumentsChecked(this.documents);
    modalRef.result.then(
      () => { },
      error => { throw error; }
    );
  }

  public onClose(): void { this.modal.dismiss(); }

  private getDocumentsChecked(documents: Document[]): Document[] {
    const result: Document[] = [];
    documents.forEach(d => {
      if (d.checked) {
        result.push(d);
      }
    });
    return result;
  }

  public onSubmit() {
    const documents: Document[] = this.getDocumentsChecked(this.documents);

    if (!documents || documents.length == 0) {
      this.noDocuments = true;
      return;
    }

    const idDocumentList: number[] = [];
    documents.forEach(doc => idDocumentList.push(doc.id));
    this.onClose();
              
    switch (this.categorie) {
      case CategorieEnum.COMMISSION:
        this.editionService.getCommissionEdition(SessionContext.get('idOrganisme'), this.commission.id)
          .subscribe(async commissionEdition => {
            if (commissionEdition.idResponsable) {

              const organismeEdition: OrganismeEdition = await this.editionService.getOrganismeEdition(SessionContext.get("idOrganisme")).toPromise();
              const responsable = await this.operateursService.getOperateur(commissionEdition.idResponsable).toPromise();
              commissionEdition.organisme = organismeEdition;
              commissionEdition.responsable = responsable.nom || responsable.prenom ? responsable.prenom.concat(' ').concat(responsable.nom) : 'N/A';
              
            } else {
              commissionEdition.responsable = '';
            }
            if (commissionEdition.echantillons.length > 0) {

              for (let i = 0; i < commissionEdition.echantillons.length; i++) {
                const operateur = await this.operateursService.getOperateur(commissionEdition.echantillons[i].operateur.id).toPromise();
                commissionEdition.echantillons[i].operateur.raisonSociale = operateur.raisonSociale ? operateur.raisonSociale : '';
                commissionEdition.echantillons[i].operateur.telephone = operateur.telephoneFixe ? operateur.telephoneFixe : '';
                commissionEdition.echantillons[i].operateur.portable = operateur.telephoneMobile ? operateur.telephoneMobile : '';
                commissionEdition.echantillons[i].operateur.fax = operateur.fax ? operateur.fax : '';
                commissionEdition.echantillons[i].operateur.ville = operateur.ville ? operateur.ville : '';
                commissionEdition.echantillons[i].operateur.siret = operateur.siret ? operateur.siret : '';
                commissionEdition.echantillons[i].operateur.codePostal = operateur.codePostal ? operateur.codePostal : '';
                commissionEdition.echantillons[i].operateur.adresse = operateur.adresse ? operateur.adresse : '';
              }

              this.editionService.genererPdfCommission(SessionContext.get('idOrganisme'), commissionEdition, idDocumentList)
                .subscribe((fichiers: Fichier[]): void => {
                  fichiers.forEach(fichier => {
                    const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                    window.open(fileURL);
                  });
                }), () => { },
                () => { };
            } else {

              commissionEdition.echantillons.forEach(e => {
                e.operateur.raisonSociale = '';
                e.operateur.telephone = '';
                e.operateur.portable = '';
                e.operateur.fax = '';
                e.operateur.siret = '';
                e.operateur.ville = '';
                e.operateur.codePostal = '';
              });

              this.editionService.genererPdfCommission(SessionContext.get('idOrganisme'), commissionEdition, idDocumentList)
                .subscribe((fichiers: Fichier[]): void => {
                  fichiers.forEach(fichier => {
                    const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                    window.open(fileURL);
                  });
                }), () => { },
                () => { };
            }

          });
        break;
      case CategorieEnum.CONTROLE:

        this.editionService.getControlesEdition(SessionContext.get('idOrganisme'), this.controle.map((ctrl) => ctrl.id ))
          .subscribe(async controleEditions => {
            const organismeEdition: OrganismeEdition = await this.editionService.getOrganismeEdition(SessionContext.get("idOrganisme")).toPromise();
            //controleEditions.forEach(controleEdition =>{                      
           
            //console.log(await controleEditions.controle);

              for (let i = 0; i < controleEditions.controles.length; i++) {

                

                controleEditions.controles[i].organisme = organismeEdition;
                
                if(controleEditions.controles[i].operateur.id){
                const operateur = await this.operateursService.getOperateur(controleEditions.controles[i].operateur.id).toPromise();
                const infosDomaine = await this.operateursService.getInformationsDomaine(controleEditions.controles[i].operateur.id).toPromise();

                controleEditions.controles[i].operateur.raisonSociale = operateur.raisonSociale ? operateur.raisonSociale : '';
                controleEditions.controles[i].operateur.telephone = operateur.telephoneFixe ? operateur.telephoneFixe : '';
                controleEditions.controles[i].operateur.portable = operateur.telephoneMobile ? operateur.telephoneMobile : '';
                controleEditions.controles[i].operateur.fax = operateur.fax ? operateur.fax : '';
                controleEditions.controles[i].operateur.adresse = operateur.adresse ? operateur.adresse : '';
                controleEditions.controles[i].operateur.date = '';
                controleEditions.controles[i].operateur.ville = operateur.ville ? operateur.ville : '';
                controleEditions.controles[i].operateur.siret = operateur.siret ? operateur.siret : '';
                controleEditions.controles[i].operateur.codePostal = operateur.codePostal ? operateur.codePostal : '';                
                controleEditions.controles[i].operateur.email = operateur.email ? operateur.email : '';

                controleEditions.controles[i].operateur.cvi = infosDomaine.find(i => i.code === 'VIN_CVI').valeur ? infosDomaine.find(i => i.code === 'VIN_CVI').valeur : '';
                }else{
                  controleEditions.controles[i].operateur.raisonSociale = '';
                  controleEditions.controles[i].operateur.telephone = '';
                  controleEditions.controles[i].operateur.portable = '';
                  controleEditions.controles[i].operateur.fax = '';
                  controleEditions.controles[i].operateur.adresse = '';
                  controleEditions.controles[i].operateur.date = '';
                  controleEditions.controles[i].operateur.ville = '';
                  controleEditions.controles[i].operateur.siret = '';
                  controleEditions.controles[i].operateur.codePostal = '';
                  controleEditions.controles[i].operateur.cvi = '';
                  controleEditions.controles[i].operateur.email = '';
                }

                controleEditions.controles[i].manquements.forEach(manquement => {
                  if (manquement.idAuteurConstat) {
                    this.operateursService.getOperateur(manquement.idAuteurConstat)
                      .subscribe(operateurManquement => {
                        manquement.auteurConstat = operateurManquement.nom || operateurManquement.prenom ? operateurManquement.prenom.concat(' ').concat(operateurManquement.nom) : '';
                        manquement.fonctionAuteur = operateurManquement.structureJuridique ? operateurManquement.structureJuridique : '';
                      });
                  } else {
                    manquement.auteurConstat = '';
                    manquement.fonctionAuteur = '';
                  }
                });

                for (let j = 0; j < controleEditions.controles[i].manquements.length; j++) {
                  if (controleEditions.controles[i].manquements[j].idAuteurConstat) {
                    const operateurManquement = await this.operateursService.getOperateur(controleEditions.controles[i].manquements[j].idAuteurConstat).toPromise();
  
                    controleEditions.controles[i].manquements[j].auteurConstat = operateurManquement.nom || operateurManquement.prenom ? operateurManquement.prenom.concat(' ').concat(operateurManquement.nom) : '';
                    controleEditions.controles[i].manquements[j].fonctionAuteur = operateurManquement.structureJuridique ? operateurManquement.structureJuridique : '';
  
                  } else {
                    controleEditions.controles[i].manquements[j].auteurConstat = '';
                    controleEditions.controles[i].manquements[j].fonctionAuteur = '';
                  }
                }

              }

              this.editionService.genererPdfControle(SessionContext.get('idOrganisme'), controleEditions, idDocumentList)
              .subscribe((fichiers: Fichier[]): void => {
                fichiers.forEach(fichier => {
                  const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                  window.open(fileURL);
                });
              }), () => { },
              () => { };   
            });           
          
        break;
      case CategorieEnum.ECHANTILLON:
        this.editionService.getEchantillonEdition(SessionContext.get('idOrganisme'), this.echantillon.id)
          .subscribe(async echantillonEdition => {
            const organismeEdition: OrganismeEdition = await this.editionService.getOrganismeEdition(SessionContext.get("idOrganisme")).toPromise();
            echantillonEdition.organisme = organismeEdition;
            forkJoin(
              this.operateursService.getOperateur(echantillonEdition.operateur.id),
              this.operateursService.getInformationsDomaine(echantillonEdition.operateur.id)
            ).pipe(
              map(([operateur, infosDomaine]) => {
                echantillonEdition.operateur.raisonSociale = operateur.raisonSociale ? operateur.raisonSociale : '';
                echantillonEdition.operateur.telephone = operateur.telephoneFixe ? operateur.telephoneFixe : '';
                echantillonEdition.operateur.portable = operateur.telephoneMobile ? operateur.telephoneMobile : '';
                echantillonEdition.operateur.fax = operateur.fax ? operateur.fax : '';
                echantillonEdition.operateur.adresse = operateur.adresse ? operateur.adresse : '';
                echantillonEdition.operateur.date = '';
                echantillonEdition.operateur.ville = operateur.ville ? operateur.ville : '';
                echantillonEdition.operateur.siret = operateur.siret ? operateur.siret : '';
                echantillonEdition.operateur.codePostal = operateur.codePostal ? operateur.codePostal : '';

                echantillonEdition.operateur.cvi = infosDomaine.find(i => i.code === 'VIN_CVI').valeur ? infosDomaine.find(i => i.code === 'VIN_CVI').valeur : '';
              })
            ).subscribe(() => {
              this.editionService.genererPdfEchantillon(SessionContext.get('idOrganisme'), echantillonEdition, idDocumentList)
                .subscribe((fichiers: Fichier[]): void => {
                  fichiers.forEach(fichier => {
                    const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                    window.open(fileURL);
                  });
                }), () => { },
                () => { };
            });
          });
        break;
      case CategorieEnum.DECLARATION:
        this.editionService.getDeclarationEdition(SessionContext.get('idOrganisme'), this.declaration.idMouvement)
          .subscribe(async declarationEdition => {
            const organismeEdition: OrganismeEdition = await this.editionService.getOrganismeEdition(SessionContext.get("idOrganisme")).toPromise();
           
            forkJoin(
              this.operateursService.getOperateur(declarationEdition.operateur.id),
              this.operateursService.getInformationsDomaine(declarationEdition.operateur.id)
            ).pipe(
              map(([operateur, infosDomaine]) => {
                declarationEdition.operateur.raisonSociale = operateur.raisonSociale ? operateur.raisonSociale : '';
                declarationEdition.operateur.telephone = operateur.telephoneFixe ? operateur.telephoneFixe : '';
                declarationEdition.operateur.portable = operateur.telephoneMobile ? operateur.telephoneMobile : '';
                declarationEdition.operateur.fax = operateur.fax ? operateur.fax : '';
                declarationEdition.operateur.adresse = operateur.adresse ? operateur.adresse : '';
                declarationEdition.operateur.date = '';
                declarationEdition.operateur.ville = operateur.ville ? operateur.ville : '';
                declarationEdition.operateur.siret = operateur.siret ? operateur.siret : '';
                declarationEdition.operateur.codePostal = operateur.codePostal ? operateur.codePostal : '';               
                declarationEdition.operateur.email = operateur.email ? operateur.email : '';

                declarationEdition.operateur.cvi = infosDomaine.find(i => i.code === 'VIN_CVI').valeur ? infosDomaine.find(i => i.code === 'VIN_CVI').valeur : '';
              })
            ).subscribe(() => {

              declarationEdition.organisme = organismeEdition;
              declarationEdition.date = declarationEdition.lots[0].date;
              declarationEdition.cahier = declarationEdition.lots[0].cahier;
              this.editionService.genererPdfDeclaration(SessionContext.get('idOrganisme'), declarationEdition, idDocumentList)
                .subscribe((fichiers: Fichier[]): void => {
                  fichiers.forEach(fichier => {
                    const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                    window.open(fileURL);
                  });
                }), () => { },
                () => { };
            });
          });
        break;
      case CategorieEnum.TOURNEE:
          this.editionService.getTourneeEdition(SessionContext.get('idOrganisme'), this.tournee.id)           
            .subscribe(async tourneeEdition => {
              const organismeEdition: OrganismeEdition = await this.editionService.getOrganismeEdition(SessionContext.get("idOrganisme")).toPromise();
                        
              for (let i = 0; i < tourneeEdition.sites.length; i++) {
                const operateur = await this.operateursService.getOperateur(tourneeEdition.sites[i].operateur.id).toPromise();
                tourneeEdition.sites[i].operateur.raisonSociale = operateur.raisonSociale ? operateur.raisonSociale : '';
                tourneeEdition.sites[i].operateur.telephone = operateur.telephoneFixe ? operateur.telephoneFixe : '';
                tourneeEdition.sites[i].operateur.portable = operateur.telephoneMobile ? operateur.telephoneMobile : '';
                tourneeEdition.sites[i].operateur.fax = operateur.fax ? operateur.fax : '';
                tourneeEdition.sites[i].operateur.ville = operateur.ville ? operateur.ville : '';
                tourneeEdition.sites[i].operateur.siret = operateur.siret ? operateur.siret : '';
                tourneeEdition.sites[i].operateur.codePostal = operateur.codePostal ? operateur.codePostal : '';
                tourneeEdition.sites[i].operateur.adresse = operateur.adresse ? operateur.adresse : '';                
                tourneeEdition.sites[i].operateur.email = operateur.email ? operateur.email : '';

                const infosDomaine = await this.operateursService.getInformationsDomaine(tourneeEdition.sites[i].operateur.id).toPromise();
                tourneeEdition.sites[i].operateur.cvi = infosDomaine.find(i => i.code === 'VIN_CVI').valeur ? infosDomaine.find(i => i.code === 'VIN_CVI').valeur : '';

              }

              tourneeEdition.organisme = organismeEdition;

              this.editionService.genererPdfTournee(SessionContext.get('idOrganisme'), tourneeEdition, idDocumentList)
                    .subscribe((fichiers: Fichier[]): void => {
                      fichiers.forEach(fichier => {
                        const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                        window.open(fileURL);
                      });
                    }), () => { },
                    () => { };
            });
          break;
      case CategorieEnum.MANQUEMENT:
            
            this.editionService.getManquementEdition(SessionContext.get('idOrganisme'),this.manquement.id)
            .subscribe(async manquementEdition => {
              const organismeEdition: OrganismeEdition = await this.editionService.getOrganismeEdition(SessionContext.get("idOrganisme")).toPromise();
              manquementEdition.organisme = organismeEdition;
            forkJoin(
              this.operateursService.getOperateur(manquementEdition.operateur.id),
              this.operateursService.getInformationsDomaine(manquementEdition.operateur.id)
            ).pipe(
              map(([operateur, infosDomaine]) => {
                manquementEdition.operateur.raisonSociale = operateur.raisonSociale ? operateur.raisonSociale : '';
                manquementEdition.operateur.telephone = operateur.telephoneFixe ? operateur.telephoneFixe : '';
                manquementEdition.operateur.portable = operateur.telephoneMobile ? operateur.telephoneMobile : '';
                manquementEdition.operateur.adresse = operateur.adresse ? operateur.adresse : '';
                manquementEdition.operateur.ville = operateur.ville ? operateur.ville : '';
                manquementEdition.operateur.siret = operateur.siret ? operateur.siret : '';
                manquementEdition.operateur.codePostal = operateur.codePostal ? operateur.codePostal : '';
                manquementEdition.operateur.mail = operateur.email ? operateur.email : '';

                manquementEdition.operateur.cvi = infosDomaine.find(i => i.code === 'VIN_CVI').valeur ? infosDomaine.find(i => i.code === 'VIN_CVI').valeur : '';
              })
            ).subscribe(() => {



              this.editionService.genererPdfManquement(SessionContext.get('idOrganisme'),manquementEdition,idDocumentList)
              .subscribe((fichiers: Fichier[]): void => {
                fichiers.forEach(fichier => {
                  const fileURL = URL.createObjectURL(FileDownloadTools.b64ToBlob(fichier.base64, 'application/pdf'));
                  window.open(fileURL);
                });
              }), () => { },
              () => { };
            });

            });

        break;
    }
  }
}
