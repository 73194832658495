import {Any, JsonObject, JsonProperty} from '@thorolf/json-ts-mapper';
import {ComplementPoPdfVer} from "../../intervenant/models/complementPoPdfVer.model";

@JsonObject
export class InfosGenerationAudit {

  @JsonProperty('idEntrepriseList', Number)
  idEntrepriseList: number[];

  @JsonProperty('mapDateValidationInventaire', Any)
  mapDateValidationInventaire: Map<number, Date>[];

  @JsonProperty('codeOCCT', String)
  codeOCCT: string;

  @JsonProperty('complementPoPdfVer', Any)
  complementPoPdfVer: ComplementPoPdfVer[];

}
