import { AdvBootstrapLoaderService } from '@adv/bootstrap-loader';
import { AdvBootstrapModalService } from '@adv/bootstrap-modal';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Router, Routes } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { pull, sortBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Campagne } from 'src/app/data/declaration/models/campagne.model';
import { Espece } from 'src/app/data/declaration/models/espece.model';
import { RecolteEspeceQuantite } from 'src/app/data/declaration/models/recolte-espece-quantite.model';
import { DeclarationsService } from 'src/app/data/declaration/services/declarations/declarations.service';
import { EspeceService } from 'src/app/data/declaration/services/parcellaire/espece.service';
import { RecoltesService } from 'src/app/data/declaration/services/recoltes/recoltes.service';
import { RefCampagnesService } from 'src/app/data/declaration/services/ref-campagnes/ref-campagnes.service';
import { StocksService } from 'src/app/data/declaration/services/stocks/stocks.service';
import { ContactReferent } from 'src/app/data/intervenant/models/contact-referent.model';
import { EntrepriseReferent } from 'src/app/data/intervenant/models/entreprise-referent.model';
import { Entreprise } from 'src/app/data/intervenant/models/entreprise.model';
import { References } from 'src/app/data/intervenant/models/references.model';
import { EntreprisesService } from 'src/app/data/intervenant/services/entreprises/entreprises.service';
import { ReferencesService } from 'src/app/data/intervenant/services/references/references.service';
import { SuiviStockModel } from 'src/app/data/suivis/models/suivi-stock.model';
import { SuivisService } from 'src/app/data/suivis/services/suivis.service';
import { EmailsHelper } from 'src/app/shared/helpers/emails.helper';
import { Email } from 'src/app/shared/interfaces/email.interface';
import { NavigatorExtended } from 'src/app/shared/interfaces/navigator-extended.interface';
import { EstimationStockComponent } from './modals/estimation-stock/estimation-stock.component';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-suivi-stocks',
  templateUrl: './suivi-stocks.component.html',
  styleUrls: ['./suivi-stocks.component.scss']
})
export class SuiviStocksComponent implements OnInit {

  // Routes
  public static routes: Routes = [{
    path: '', component: SuiviStocksComponent, data: {
      role: 'ORGANISME',
      domaines: ['ANPP'],
      menu: {
        libelle: 'menu.suivi-stocks',
        icon: 'cogs'
      }
    }
  }];

  public references: References;
  mois: number[] = [11, 12, 1, 2, 3, 4, 5, 6, 7, 8]
  numMois: number = 0;
  campagne: Campagne;
  campagnes: Campagne[];
  entreprises: Entreprise[];
  entreprisesReferents: EntrepriseReferent[];
  public entreprisesSelectionnees: Array<number> = [];
  suivis: SuiviStockModel[];
  suivisFilter: SuiviStockModel[];
  pourcentMisAJour = 0;
  pourcentTonnage = 0;
  totalNbEntreprises = 0;
  totalTonnesDeclarees = 0;
  totalTonnesRecoltees = 0;
  especes: Espece[];
  recoltes: RecolteEspeceQuantite[];
  isAnpp: Boolean;


  public etats: Array<{ code: number, libelle: string }> = [
    { code: 1, libelle: 'page.suivi.suivi-stocks.manquantes' },
    { code: 2, libelle: 'page.suivi.suivi-stocks.recues' }
  ];

  public filtersForm: FormGroup;
  public showFilters: boolean = false;

  // getters privés
  private get _campagne(): AbstractControl { return this.filtersForm.get('campagne'); }
  private get _mois(): AbstractControl { return this.filtersForm.get('mois'); }
  private get _espece(): AbstractControl { return this.filtersForm.get('espece'); }
  private get _type(): AbstractControl { return this.filtersForm.get('type'); }
  private get _terroir(): AbstractControl { return this.filtersForm.get('terroir'); }
  private get _ecoulementRecu(): AbstractControl { return this.filtersForm.get('ecoulementRecu'); }
  get campagneSel() { return this.filtersForm.get('selectionCampagne'); }
  get moisSel() { return this.filtersForm.get('selectionMois'); }



  constructor(
    private suivisService: SuivisService,
    private readonly campagneService: RefCampagnesService,
    private readonly entrepriseService: EntreprisesService,
    private referencesService: ReferencesService,
    private stockService: StocksService,
    private recolteService: RecoltesService,
    private router: Router,
    private readonly translate: TranslateService,
    private readonly translateService: TranslateService,
    private readonly toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modal: NgbModal,
    private readonly declarationsService: DeclarationsService,
    private readonly modalsService: AdvBootstrapModalService,
    private readonly especeService: EspeceService,
    private readonly loaderService: AdvBootstrapLoaderService,
    private readonly authService: AuthService
  ) { }

  ngOnInit() {
    this.suivisService.setActiveTabId('stocks');
    this.isAnpp = this.authService.isAnpp();
    // Initialiser le formulaire de recherche avancée
    this.filtersForm = this.formBuilder.group({
      campagne: [-1],
      espece: [-1],
      type: [-1],
      terroir: [-1],
      mois: [-1],
      ecoulementRecu: [-1]
    });

    let today = new Date();
    this.numMois = today.getMonth() + 1; // (today.getDate() < 25) ? (today.getMonth() + 1)  : (today.getMonth() + 2);
    if (!this.mois.indexOf(this.numMois)) {
      this.numMois = this.mois[0];
    }
    const leMois = this.mois.find(m => m == this.numMois);
    this._mois.setValue(leMois);


    forkJoin([
      this.campagneService.getCampagneAll(),
      this.especeService.getEspeces(),
      this.entrepriseService.getEntreprisesReferents(),
      this.entrepriseService.getEntreprisesBySousRefType(["PI_DEFAUT", "GP_DEFAUT"]),
      this.campagneService.getCampagneEnCours().pipe(
        mergeMap((campagne) => {
          this.campagne = campagne;
          return this.stockService.getSuivisStocks(campagne.id, this.numMois);
        })),
      this.referencesService.getReferences()])
      .pipe(this.loaderService.operator())
      .subscribe(([campagnes, especes, entreprisesReferents, entreprises, retourSuivis, references]) => {
        this.campagnes = campagnes.sort((a, b) => {
          if (a && b && a.annee > b.annee) return -1;
          if (a && b && a.annee < b.annee) return 1;
          return 0;
        });
        this.entreprises = entreprises;
        this.entreprisesReferents = entreprisesReferents;
        this.suivis = [];
        this.references = references;
        this.especes = especes;
        const camp = this.campagnes.find(c => c.id === this.campagne.id);
        this._campagne.setValue(camp);

        this.recoltes = [];

        this.loadData();

        this.references = references;
        this.suivisFilter = this.suivis;
        this.calculerPourcentages();
      });

  }


  loadData() {
    forkJoin([
      this.stockService.getSuivisStocks(this.campagne.id, this.numMois),
      this.recolteService.getRecoltesEspecesEntreprises(this.campagne.id),
      this.isAnpp ? this.campagneService.getIdEntrepriseDroitStockFalse(this.campagne.id) : of(null)])
      .pipe(this.loaderService.operator())
      .subscribe(([retourSuivis, retourRecoltes, entrepriseSansDroit]) => {

        this.suivis = [];
        this.recoltes = [];

        this.entreprises.forEach((e, index) => {
          if (!entrepriseSansDroit ||(entrepriseSansDroit&&!entrepriseSansDroit.includes(e.id))) {
            let suivisEntreprise = retourSuivis.filter(rs => rs.idEntreprise === e.id);
            if (suivisEntreprise) {

              if (suivisEntreprise === undefined || suivisEntreprise.length === 0) {
                // une ligne vide pour l'entreprise
                let suivi = new SuiviStockModel();
                suivi.entreprise = e;
                suivi.idEntreprise = e.id;
                this.suivis.push(suivi);
              } else {
                suivisEntreprise.forEach(theSuivi => {
                  // une ligne par especes trouvées
                  let suivi = new SuiviStockModel();
                  suivi.entreprise = e;
                  suivi.idEntreprise = e.id;
                  suivi.qteBio = theSuivi.qteBio;
                  suivi.qteTotale = theSuivi.qteTotale;
                  suivi.dateDeclaration = theSuivi.dateDeclaration;
                  suivi.idDeclaration = theSuivi.idDeclaration;
                  suivi.codeEspece = theSuivi.codeEspece;
                  this.suivis.push(suivi);
                });
              }
            }
          }
          let recolteEntreprise = retourRecoltes.filter(rs => rs.idEntreprise === e.id);
          if (recolteEntreprise) {
            if (recolteEntreprise.length > 0) {
              recolteEntreprise.forEach(recolte => {
                // une ligne par especes trouvées
                this.recoltes.push(recolte);
              });
            }
          };

        });

        this.filter();
        this.calculerPourcentages();
      });
  }

  public onFilterClick(): void {
    this.showFilters = !this.showFilters;
  }


  public filter(): void {
    const campagne = this._campagne.value;
    const espece = this._espece.value;
    const type = this._type.value;
    const terroir = this._terroir.value;
    const ecoulementRecu = this._ecoulementRecu.value;
    this.suivisFilter = this.suivis.filter((declaration: SuiviStockModel) => {

      const entreprise = this.entreprisesReferents.find((entreprise: EntrepriseReferent) => entreprise.id == declaration.idEntreprise);
      if (espece != "-1" && (declaration.codeEspece != espece && declaration.codeEspece)) {
        return false;
      }
      if (type != -1 && (entreprise == null || entreprise.sousType.id != type)) {
        return false;
      }
      if (terroir != -1 && (entreprise == null || terroir.indexOf(entreprise.departement) == -1)) {
        return false;
      }
      if ((ecoulementRecu == 1 && declaration.dateDeclaration) || (ecoulementRecu == 2 && !declaration.dateDeclaration)) {
        return false;
      }

      return true;

    });
    this.sortList();
  }

  // tri par espece (Pomme, poire, cerise) puis raison sociale
  private sortList() {
    this.suivisFilter.sort((n1, n2) => {
      const especeOrder = ["POMME", "POIRE", "CERISE"]; // Ordre défini

      // On récupère l'index de l'espèce ou on met un index élevé pour les espèces inconnues
      const indexN1 = n1.codeEspece && especeOrder.includes(n1.codeEspece)
        ? especeOrder.indexOf(n1.codeEspece)
        : especeOrder.length;
      const indexN2 = n2.codeEspece && especeOrder.includes(n2.codeEspece)
        ? especeOrder.indexOf(n2.codeEspece)
        : especeOrder.length;

      // Tri par espèce (POMME -> POIRE -> CERISE -> Autres)
      if (indexN1 !== indexN2) {
        return indexN1 - indexN2;
      }

      // Si même espèce ou espèces inconnues, tri par raison sociale
      return this.getLibelleEntreprise(n1.idEntreprise).localeCompare(this.getLibelleEntreprise(n2.idEntreprise));
    });
  }

  public getLibelleEntreprise(idEntreprise: number): string {
    const entreprise = this.entreprises.find((entreprise: Entreprise) => entreprise.id == idEntreprise);
    return entreprise ? entreprise.raisonSociale : '';
  }

  getLibelleMois(numMois: number): string {
    let date = new Date(2022, numMois - 1, 15);
    return date.toLocaleString(undefined, { month: 'long' });
  }

  onCampagneChange() {
    this.campagne = this.filtersForm.get('campagne').value as Campagne;

    this.loadData();
    this.filter();
  }

  onMoisChange() {
    this.numMois = this.filtersForm.get('mois').value as number;

    this.loadData();
    this.filter();
  }


  calculerPourcentages() {
    this.totalNbEntreprises = this.suivisFilter.length;


    if (this.suivisFilter && this.suivisFilter.length > 0) {
      this.pourcentMisAJour = this.suivisFilter.filter(s => s.dateDeclaration).length / this.totalNbEntreprises * 100;
    } else {
      this.pourcentMisAJour = undefined;
    }

    if (this.recoltes && this.recoltes.length > 0) {
      this.totalTonnesRecoltees = 0;
      this.totalTonnesDeclarees = 0;
      this.recoltes.forEach(recolte => {
        this.totalTonnesRecoltees += recolte.quantite;
      });
      this.suivisFilter.forEach(suivi => {
        if (suivi.qteTotale)
          this.totalTonnesDeclarees += suivi.qteTotale;
      });
    }
    this.pourcentTonnage = this.totalTonnesDeclarees / this.totalTonnesRecoltees * 100;
  };

  onReactivationStock2(idEntreprise: Number) {

    this.stockService.razStockAZero(idEntreprise, undefined, undefined);

  }



  onReactivationStock(idEntreprise: Number) {
    this.modalsService.confirm(
      this.translate.instant('page.suivi.suivi-stocks.action-reactivation-confirm-message'),
      this.translate.instant(`page.suivi.suivi-stocks.action-reactivation-confirm-titre`), {
      cancelText: this.translate.instant(`label.annuler`),
      submitText: this.translate.instant(`label.confirmer`),
      windowClass: 'anpp'
    }
    ).then(() => {
      this.stockService.razStockAZero(idEntreprise, this.campagne.id, this.numMois).subscribe(
        response => {
          this.toastr.success(
            '',
            this.translate.instant('page.suivi.suivi-stocks.action-reactivation-ok'),
            { timeOut: 10 * 1000 }
          );
          this.loadData();
          this.filter();
        }
      );
    }, () => { });
  }


  onGoToDeclarationStock(idEntreprise: Number) {
    this.declarationsService.entrepriseSelectionnee = <Entreprise>{
      id: idEntreprise,
      //raisonSociale: this.getLibelleEntreprise(idEntreprise)
    };
    this.router.navigate([`/main/declarations-pp/stocks/${idEntreprise}`]);
  }

  public onOpenEstimations(): void {
    this.modal.open(EstimationStockComponent, { backdrop: 'static', windowClass: 'anpp' });
  }

  /**
   * copie dans le presse papier des emails des contacts référents de la ligne sélectionnée
   * @param suivi
   */
  getEmails(suivi: SuiviStockModel) {
    const referentsEmails = this.getReferentsEmails(suivi.idEntreprise);
    if (referentsEmails != null) {
      (navigator as NavigatorExtended).clipboard.writeText(referentsEmails.join(';')).then(() => {
        this.toastr.success(
          this.translateService.instant('page.suivi.suivi-stocks.emails-copies-succes'),
          this.translateService.instant('page.suivi.suivi-stocks.emails-copies-titre'),
          { timeOut: 10 * 1000 }
        );
      }
      ).catch(e => console.error(e));
    } else {
      this.toastr.warning(
        this.translateService.instant('page.suivi.suivi-stocks.emails-copies-erreur'),
        this.translateService.instant('page.suivi.suivi-stocks.emails-copies-titre'),
        { timeOut: 10 * 1000 }
      );
    }
  }

  private getReferentsEmails(idEntreprise: number): Array<string> {
    const entreprise = this.entreprisesReferents.find((entreprise: EntrepriseReferent) => entreprise.id === idEntreprise);

    return (entreprise && entreprise.referentStockList.length > 0)
      ? entreprise.referentStockList.map((contact: ContactReferent) => contact.mail)
      : null;
  }


  public onCheckAllDeclarations(event: any): void {
    this.entreprisesSelectionnees = [];
    this.suivisFilter.forEach((declaration: SuiviStockModel) => {
      declaration.estCochee = event.target.checked;
      if (event.target.checked && !this.entreprisesSelectionnees.includes(declaration.idEntreprise)) {
        this.entreprisesSelectionnees.push(declaration.idEntreprise);
      }
    });
  }

  public onCheckDeclaration(event: Event, declaration: SuiviStockModel): void {
    const isChecked = (event.target as HTMLInputElement).checked;

    // Met à jour l'état 'estCochee' de la déclaration selon l'état de la case cochée
    declaration.estCochee = isChecked;

    // Vérifie si la case est cochée et l'entreprise n'est pas encore dans la liste
    if (isChecked && !this.entreprisesSelectionnees.includes(declaration.idEntreprise)) {
        // Ajoute l'entreprise sélectionnée à la liste
        this.entreprisesSelectionnees.push(declaration.idEntreprise);
    }
    // Sinon, si la case est décochée et que l'entreprise est dans la liste
    else if (!isChecked) {
        // Retire l'entreprise de la liste
        pull(this.entreprisesSelectionnees, declaration.idEntreprise);
    }
}

  public onRelanceGroupee(): void {
    let referentsEmails: Array<string> = [];
    this.entreprisesSelectionnees.forEach((idEntreprise: number) =>
      referentsEmails = referentsEmails.concat(this.getReferentsEmails(idEntreprise))
    );
    referentsEmails = referentsEmails.filter(x => x != undefined && x !== '');
    if (referentsEmails != null && referentsEmails.length > 0) {
      //EmailsHelper.envoyerEMail(<Email>{ cci: referentsEmails });
      (navigator as NavigatorExtended).clipboard.writeText(referentsEmails.join(';')).then(() => {
        this.toastr.success(
          this.translateService.instant('page.suivi.suivi-stocks.emails-copies-succes'),
          this.translateService.instant('page.suivi.suivi-stocks.emails-copies-titre'),
          { timeOut: 10 * 1000 }
        );
      }
      ).catch(e => console.error(e));
    } else {
      this.toastr.error(this.translateService.instant('page.suivi.suivi-ecoulements.emails-copie-erreur'));
    }
  }

}
