import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EntrepriseEngagement } from "../../../../../../data/habilitation/models/engagements/entreprise-engagement";
import { EngagementVergersEcoService } from "../../../../../../data/habilitation/services/engagements/engagement-vergers-eco.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UniteCulturaleService } from "../../../../../../data/declaration/services/parcellaire/unite-culturale.service";
import { InfosLastValid } from "../../../../../../data/declaration/models/infos-last-validation.model";
import { InfosGenerationAudit } from "../../../../../../data/declaration/models/infos-generation-audit.model";
import { EngagementBase } from "../../../../../../data/habilitation/models/engagements/engagement-base";
import { GedFacade } from "src/app/data/edition/facades/ged.facade";
import { GedFichier } from "src/app/data/edition/models/ged-fichier.model";
import { zip } from "rxjs";
import { RefTypeFichier } from "src/app/data/edition/models/ref-type-fichier.model";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { SessionContext } from "src/app/core/services/config/app.settings";
import * as moment from "moment";
import { NotificationDocument } from "src/app/data/intervenant/models/notification-document.model";
import { UtilisateursService } from "src/app/data/intervenant/services/utilisateurs/utilisateurs.service";
import { AdvBootstrapLoaderService } from "@adv/bootstrap-loader";
import {EntreprisesService} from "../../../../../../data/intervenant/services/entreprises/entreprises.service";

@Component({
  selector: "app-doc-preparatoire-oc",
  templateUrl: "./doc-preparatoire-oc.component.html",
  styleUrls: ["./doc-preparatoire-oc.component.scss"],
})
export class DocPreparatoireOCComponent implements OnInit {
  @Input() public campagne;
  @Input() public campagneInventaire;
  @Input() public engagements: EngagementBase[];
  formGroup: FormGroup;
  orgaCollecteursList: EntrepriseEngagement[] = [];
  adherentsList: EntrepriseEngagement[] = [];
  idEntrepriseList: number[] = [];
  private idOrgaCollecteurList: number[] = [];
  headerselect: EntrepriseEngagement[];
  infosLastValidDateInventaireList: InfosLastValid[];
  infosGenerationAudit: InfosGenerationAudit;

  constructor(
    private engagementVergersEcoService: EngagementVergersEcoService,
    public fb: FormBuilder,
    public gedFacade: GedFacade,
    public modal: NgbActiveModal,
    private uniteCulturaleService: UniteCulturaleService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private utilisateursService: UtilisateursService,
    private loaderService: AdvBootstrapLoaderService,
    private entreprisesService: EntreprisesService
  ) {}

  get multis() {
    return this.formGroup.get("multis");
  }
  get selectedOrgaCollecteur() {
    return this.formGroup.get("orgaCollecteur").value as EntrepriseEngagement;
  }

  ngOnInit() {
    this.gedFacade.setIdIntervenant(SessionContext.get("idIntervenant"));
    this.gedFacade.loadRefTypesFichier();
    this.engagements.forEach((e) => {
      //if (e.codeStatut === 'DP') {
      if (
        !this.orgaCollecteursList.find(
          (oc) => oc.id === e.organismeCertification.id
        )
      ) {
        this.orgaCollecteursList.push(e.organismeCertification);
      }
      //}
    });

    if (this.adherentsList) {
      this.headerselect = this.adherentsList.filter(
        (header) => header.raisonSociale
      );
    }
    this.formGroup = this.fb.group({
      multis: [undefined],
      orgaCollecteur: [undefined],
    });
  }

  cancel() {
    this.modal.dismiss();
  }

  changeValueOrgaCollecteur() {
    this.adherentsList = [];
    this.formGroup.get("multis").setValue([]);
    if (this.selectedOrgaCollecteur == null) {
    } else {
      this.engagements.forEach((e) => {
        //if (e.codeStatut === 'DP' && e.organismeCertification.id === this.selectedOrgaCollecteur.id) {
        if (e.organismeCertification.id === this.selectedOrgaCollecteur.id) {
          if (!this.adherentsList.find((a) => a.id === e.entreprise.id)) {
            this.adherentsList.push(e.entreprise);
            this.idEntrepriseList.push(e.entreprise.id);
          }
        }
      });
    }
  }

  buildDocumentAudit() {
    this.idOrgaCollecteurList.push(this.selectedOrgaCollecteur.id);
    this.engagementVergersEcoService
      .getIdsEntreprisesLieesOc(
        this.selectedOrgaCollecteur.id,
        this.campagne.code,
        true
      )
      .pipe(this.loaderService.operator())
      .subscribe((ids) => {
        if (ids && ids.length > 0) {
          this.entreprisesService.getInformationsComplementairesPdfVer(ids)
            .pipe(this.loaderService.operator())
            .subscribe((result) => {
              this.uniteCulturaleService
                .getListMapEntrepriseDateValidationInventaire(
                  ids,
                  this.campagneInventaire.id
                )
                .pipe(this.loaderService.operator())
                .subscribe((map) => {
                  this.uniteCulturaleService
                    .getDateLastValidInventaireVerger(
                      this.idEntrepriseList,
                      this.campagneInventaire.id
                    )
                    .pipe(this.loaderService.operator())
                    .subscribe((infos) => {
                      this.infosLastValidDateInventaireList = infos;
                      this.infosGenerationAudit = new InfosGenerationAudit();
                      this.infosGenerationAudit.idEntrepriseList =
                        this.idEntrepriseList;
                      this.infosGenerationAudit.mapDateValidationInventaire = map;
                      this.infosGenerationAudit.codeOCCT = "OC";
                      this.infosGenerationAudit.complementPoPdfVer = result;
                      this.engagementVergersEcoService
                        .generationDocumentAudit(
                          this.campagne.code,
                          this.infosGenerationAudit
                        )
                        .subscribe((response: Blob) => {
                          const filename = `audit-oc_${
                            this.selectedOrgaCollecteur.raisonSociale
                          }_${moment().format("YYYY-MM-DDTHHmmss")}.csv`;
                          zip(
                            this.gedFacade.postUploadFile(
                              new File([response], filename)
                            ),
                            this.gedFacade.refTypesFichier$
                          ).subscribe(
                            ([uuid, typesFichiers]: [
                              string,
                              Array<RefTypeFichier>
                            ]) => {
                              this.gedFacade
                                .postFile(
                                  Object.assign(new GedFichier(), {
                                    uuid: uuid,
                                    libelle: filename,
                                    typeFichier: typesFichiers.find(
                                      (typeFichier: RefTypeFichier) =>
                                        typeFichier.code === "DOCUMENT"
                                    ),
                                    estPublic: false,
                                    estArchive: false,
                                    entreprises: this.idOrgaCollecteurList,
                                  })
                                )
                                .subscribe();
                              //récupérer les ids des intervenants associés à l'entreprise OC
                              this.utilisateursService
                                .getIntervenantsOrganismeCertificateur(
                                  this.selectedOrgaCollecteur.id
                                )
                                .pipe(this.loaderService.operator())
                                .subscribe((intervenants) => {
                                  let notif: NotificationDocument = <
                                    NotificationDocument
                                    >{
                                    idsIntervenant: intervenants.map(
                                      ({ id }) => id
                                    ),
                                    nomDocument: filename,
                                  };
                                  this.utilisateursService
                                    .sendNotificationDocumentaire(notif)
                                    .pipe(this.loaderService.operator())
                                    .subscribe();
                                  this.toastrService.success(
                                    this.translate.instant(
                                      "page.documents.documents-enregistre"
                                    )
                                  );
                                });
                            }
                          );
                          //this.downloadFile(response);
                          this.modal.close();
                        });
                    });
                });
            });
        }
      });
  }

  downloadFile(data: Blob) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    window.open(window.URL.createObjectURL(blob));
  }
}
